import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

// APIs
import companyApi from 'lib/api/company';
import streamApi from 'lib/api/stream';
import company from 'utils/company';

import { CorpActionReduxState } from './CorpAction/types';
import {
  ConsensusPayload,
  CorpActionParams,
  CorpActionPayload,
  FinancialParamPayload,
  FinancialParams,
  FinancialPayload,
  InsiderParams,
  InsiderPayload,
  KeystatsPayload,
  ProfileInfoPayload,
  SeasonalityParams,
  SeasonalityPayload,
  AnalystPayload,
  PinnedPostPayload,
  HoldingCompositionState,
  LoadingPayload,
  CompanySubsidiaryPayload,
} from './slice.types';

const holdingCompositionInitialState: HoldingCompositionState = {
  data: {},
  error: {},
  isLoading: false,
};
const corpActionInitialState: CorpActionReduxState = {};

// Initial State
const initialState = {
  profile: {},
  holdingComposition: holdingCompositionInitialState,
  corpAction: corpActionInitialState,
  consensus: {},
  insider: {},
  seasonality: {},
  keystat: {},
  financial: {},
  analyst: {},
  analystConsensus: {},
  isLoading: false,
  isLoadingAnalystConsensus: false,
  isLoadingCompanySubsidiary: false,
  message: '',
  error: null,
  financialParams: {
    report: 1,
    statement: 1,
  },
  pinnedPost: {
    data: {},
    isLoading: {},
    message: {},
    error: {},
  },
  companySubsidiary: {},
};

type CompanyProfileState = typeof initialState;

// Selector
const companySelector = (state) => state.companyProfile;
export const selectors = createSelector(
  companySelector,
  (company: CompanyProfileState) => ({
    ...company,
  }),
);

export const loadingSelector = createSelector(
  (state) => state.companyProfile.isLoading,
  (state) => state,
);

export const profileSelector = createSelector(
  (state) => state.companyProfile.profile,
  (state) => state,
);

export const holdingCompositionSelector = createSelector(
  (state) => state.companyProfile.holdingComposition,
  (state) => state,
);

export const corpActionSelector = createSelector(
  (state) => state.companyProfile.corpAction,
  (state) => state,
);

export const consensusSelector = createSelector(
  (state) => state.companyProfile.consensus,
  (state) => state,
);

export const insiderSelector = createSelector(
  (state) => state.companyProfile.insider,
  (state) => state,
);

export const seasonalitySelector = createSelector(
  (state) => state.companyProfile.seasonality,
  (state) => state,
);

export const keystatSelector = createSelector(
  (state) => state.companyProfile.keystat,
  (state) => state,
);

export const financialSelector = createSelector(
  (state) => state.companyProfile.financial,
  (state) => state,
);

export const analystSelector = createSelector(
  (state) => state.companyProfile.analyst,
  (state) => state,
);

export const analystConsensusSelector = createSelector(
  (state) => state.companyProfile.analystConsensus,
  (state) => state,
);

export const loadingAnalystConsensusSelector = createSelector(
  (state) => state.companyProfile.isLoadingAnalystConsensus,
  (state) => state,
);

export const messageSelector = createSelector(
  (state) => state.companyProfile.message,
  (state) => state,
);

export const errorSelector = createSelector(
  (state) => state.companyProfile.error,
  (state) => state,
);

export const financialParamsSelector = createSelector(
  (state) => state.companyProfile.financialParams,
  (state) => state,
);

export const pinnedPostSelector = createSelector(
  (state) => state.companyProfile.pinnedPost,
  (state) => state,
);

// Action types
const CONTEXT = '@feature/company-profile';

const actionType = {
  GET_PROFILE_INFO: `${CONTEXT}/GET_PROFILE_INFO`,
  GET_HOLDING_COMPOSITION: `${CONTEXT}/GET_PROFILE_HOLDING_COMPOSITION`,
  GET_CONSENSUS: `${CONTEXT}/GET_CONSENSUS`,
  GET_ALL_CORP_ACTION: `${CONTEXT}/GET_ALL_CORP_ACTION`,
  GET_CORP_ACTION: `${CONTEXT}/GET_CORP_ACTION`,
  GET_COMPANY_INSIDER: `${CONTEXT}/GET_COMPANY_INSIDER`,
  GET_COMPANY_SEASONALITY: `${CONTEXT}/GET_COMPANY_SEASONALITY`,
  GET_KEYSTAT: `${CONTEXT}/GET_KEYSTAT`,
  GET_FINANCIAL_REPORT: `${CONTEXT}/GET_FINANCIAL_REPORT`,
  GET_ANALYST: `${CONTEXT}/GET_ANALYST`,
  GET_PINNED_POST: `${CONTEXT}/GET_PINNED_POST`,
  GET_ANALYST_CONSENSUS: `${CONTEXT}/GET_ANALYST_CONSENSUS`,
  GET_COMPANY_SUBSIDIARY: `${CONTEXT}/GET_COMPANY_SUBSIDIARY`,
};
export const actions = actionType;

// Effects
export const effects = {
  getCompanyProfile: createAsyncThunk<ProfileInfoPayload, string>(
    actionType.GET_PROFILE_INFO,
    async (symbol) => {
      try {
        const response = await companyApi.getCompanyProfile(symbol);

        if (!response.data) {
          throw new Error('Attempt to get company profile info failed');
        }

        const { data, error, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, symbol };
      } catch (error) {
        return { error };
      }
    },
  ),
  getAllCorpAction: createAsyncThunk<any, string>(
    actionType.GET_ALL_CORP_ACTION,
    async (symbol) => {
      try {
        const response = await companyApi.getAllCorpAction(symbol);

        if (!response.data) {
          throw new Error(
            `Attempt to get all corp. action of ${symbol} failed!`,
          );
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { message, data, symbol };
      } catch (error) {
        return { error };
      }
    },
  ),
  getCompanyCorpAction: createAsyncThunk<CorpActionPayload, CorpActionParams>(
    actionType.GET_CORP_ACTION,
    async ({ symbol, category }) => {
      try {
        const response = await companyApi.getCompanyCorpAction(
          symbol,
          category,
        );

        if (!response.data) {
          throw new Error(`Attempt to get corp. action ${category} failed`);
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, symbol, category };
      } catch (error) {
        return { error };
      }
    },
  ),
  getCompanyInsider: createAsyncThunk<InsiderPayload, InsiderParams>(
    actionType.GET_COMPANY_INSIDER,
    async ({ symbol, page }) => {
      try {
        const response = await companyApi.getCompanyMajorholder({
          symbol,
          page,
        });

        if (!response.data) {
          throw new Error('Attempt to get company insider failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return {
          data: data.movement,
          message,
          symbol,
          isMore: data.is_more,
          page,
        };
      } catch (error) {
        return { error };
      }
    },
  ),
  getCompanySeasonality: createAsyncThunk<
    SeasonalityPayload,
    SeasonalityParams
  >(actionType.GET_COMPANY_SEASONALITY, async ({ symbol, year, backYear }) => {
    try {
      const response = await companyApi.getCompanySeasonality(
        symbol,
        year,
        backYear,
      );

      if (!response.data) {
        throw new Error('Attempt to get company seasonality failed');
      }

      const { error, message, data: oldData } = response.data;
      let data = oldData || {};

      if (oldData) {
        data = company.transformResponseSeasonalityFromServer(oldData);
      }

      if (error) {
        return { error, message };
      }

      return { data, message, symbol };
    } catch (error) {
      return { error };
    }
  }),
  getCompanyKeystat: createAsyncThunk<KeystatsPayload, string>(
    actionType.GET_KEYSTAT,
    async (symbol) => {
      try {
        const getKeystat = await companyApi.getCompanyKeystat(symbol);

        if (!getKeystat.data) {
          throw new Error('Attempt to get company keystat failed');
        }

        const { error_type: error, data, message } = getKeystat.data;

        if (error) {
          return { error, message };
        }

        return {
          data,
          symbol,
        };
      } catch (error) {
        return { error };
      }
    },
  ),
  getFinancialReport: createAsyncThunk<FinancialPayload, FinancialParams>(
    actionType.GET_FINANCIAL_REPORT,
    async ({ symbol, ...params }) => {
      try {
        const response = await companyApi.getFinancialReport({
          symbol,
          ...params,
        });

        if (!response.data) {
          throw new Error('Attempt to get financial report failed');
        }

        const { error, data, message } = response;

        if (error) {
          return { error, message };
        }

        return { data, message, symbol };
      } catch (error) {
        return { error };
      }
    },
  ),
  getAnalystRating: createAsyncThunk<any, string>(
    actionType.GET_ANALYST,
    async (symbol) => {
      try {
        const analystRating = await companyApi.getAnalystRating(symbol);
        if (!analystRating.data) {
          throw new Error('Attempt to get company keystat failed');
        }

        const { error_type: error, data, message } = analystRating.data;

        if (error) {
          return { error, message };
        }

        return {
          data,
          symbol,
        };
      } catch (error) {
        return { error };
      }
    },
  ),
  getAnalystConsensus: createAsyncThunk<any, string>(
    actionType.GET_ANALYST_CONSENSUS,
    async (symbol) => {
      try {
        const analystConsensus = await companyApi.getAnalystConsensus(symbol);
        if (!analystConsensus.data) {
          throw new Error('Attempt to get company keystat failed');
        }

        const { error_type: error, data, message } = analystConsensus.data;

        if (error) {
          return { error, message };
        }

        return {
          data,
          symbol,
        };
      } catch (error) {
        return { error };
      }
    },
  ),
  getPinnedPost: createAsyncThunk<PinnedPostPayload, string>(
    actionType.GET_PINNED_POST,
    async (symbol) => {
      try {
        if (!symbol) {
          throw new Error('Symbol is required!');
        }

        const response = await streamApi.getCompanyPinnedPost(symbol);

        if (!response.data) {
          throw new Error('Attempt to get company pinned post failed');
        }

        const { error_type: error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, symbol };
      } catch (error) {
        return { error };
      }
    },
  ),
  getCompanySubsidiary: createAsyncThunk<any, string>(
    actionType.GET_COMPANY_SUBSIDIARY,
    async (symbol) => {
      try {
        const companySubsidiary = await companyApi.getCompanySubsidiary(symbol);
        if (!companySubsidiary.data) {
          throw new Error('Attempt to get company keystat failed');
        }

        const { error_type: error, data, message } = companySubsidiary.data;

        if (error) {
          return { error, message };
        }

        return {
          data,
          symbol,
        };
      } catch (error) {
        return { error };
      }
    },
  ),
};

// Reducers
const reducers = {
  updateFinancialParams: (
    state: CompanyProfileState,
    action: PayloadAction<FinancialParamPayload>,
  ): CompanyProfileState => ({
    ...state,
    financialParams: {
      ...state.financialParams,
      ...action.payload,
    },
  }),
  updateLoadingState: (
    state: CompanyProfileState,
    action: PayloadAction<LoadingPayload>,
  ): CompanyProfileState => ({
    ...state,
    isLoading: action.payload.isLoading,
  }),
  resetErrorState: (state: CompanyProfileState): CompanyProfileState => ({
    ...state,
    error: null,
  }),
};

const extraReducers = (builder) => {
  builder
    .addCase(
      effects.getCompanyProfile.fulfilled,
      (
        state: CompanyProfileState,
        action: PayloadAction<ProfileInfoPayload>,
      ) => {
        const { error, data, symbol, message } = action.payload;
        if (error) {
          state.error = error;
        } else {
          state.profile[symbol] = data;
        }
        state.isLoading = false;
        state.message = message;
      },
    )
    .addCase(
      effects.getAllCorpAction.pending,
      (
        state: CompanyProfileState,
        action: PayloadAction<CorpActionPayload, any, any>,
      ) => {
        const symbol = action.meta.arg;
        state.corpAction[symbol] = {
          ...state.corpAction[symbol],
          all: {
            ...state.corpAction[symbol]?.all,
            isLoading: true,
          },
        };
      },
    )
    .addCase(
      effects.getAllCorpAction.fulfilled,
      (
        state: CompanyProfileState,
        action: PayloadAction<CorpActionPayload>,
      ) => {
        const { error, message, data, symbol } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.corpAction[symbol] = {
            ...state.corpAction[symbol],
            all: {
              isLoading: false,
              data: data.map((x) => {
                // BE res is 'stock_reverse' for category 'reversesplit', so we need to map it to our key
                if (x.action_type === 'stock_reverse') {
                  return {
                    action_type: 'reversesplit',
                    action_info: {
                      reversesplit: x.action_info.stock_reverse,
                    },
                  };
                }
                return x;
              }),
            },
          };
        }

        state.message = message;
        state.isLoading = false;
      },
    )
    .addCase(
      effects.getCompanyCorpAction.pending,
      (
        state: CompanyProfileState,
        action: PayloadAction<CorpActionPayload, any, any>,
      ) => {
        const { symbol, category } = action.meta.arg;
        state.corpAction[symbol] = {
          ...state.corpAction[symbol],
          [category]: {
            ...state.corpAction[symbol]?.[category],
            isLoading: true,
          },
        };
      },
    )
    .addCase(
      effects.getCompanyCorpAction.fulfilled,
      (
        state: CompanyProfileState,
        action: PayloadAction<CorpActionPayload>,
      ) => {
        const { error, data, symbol, category, message } = action.payload;
        if (error) {
          state.error = error;
        } else {
          const keys = Object.keys(data);
          keys.forEach((item) => {
            if (Array.isArray(data[item])) {
              state.corpAction[symbol] = {
                ...state.corpAction[symbol],
                [category]: {
                  isLoading: false,
                  data: data[item],
                },
              };
            }
          });
        }

        state.message = message;
      },
    )
    .addCase(
      effects.getCompanyInsider.pending,
      (
        state: CompanyProfileState,
        action: PayloadAction<InsiderPayload, string, any>,
      ) => {
        const { page } = action.meta.arg;
        if (page === 1) {
          state.isLoading = true;
        }
      },
    )
    .addCase(
      effects.getCompanyInsider.fulfilled,
      (state: CompanyProfileState, action: PayloadAction<InsiderPayload>) => {
        const { error, data, message, symbol, page } = action.payload;
        if (error) {
          state.error = error;
        } else {
          const insiderSymbol = state.insider[symbol] || [];
          state.insider[symbol] =
            page === 1 ? data : [...insiderSymbol, ...data];
        }

        state.isLoading = false;
        state.message = message;
      },
    )
    .addCase(
      effects.getCompanySeasonality.fulfilled,
      (
        state: CompanyProfileState,
        action: PayloadAction<SeasonalityPayload>,
      ) => {
        const { error, data, message, symbol } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.seasonality[symbol] = data;
        }

        state.isLoading = false;
        state.message = message;
      },
    )
    .addCase(
      effects.getCompanyKeystat.pending,
      (state: CompanyProfileState) => {
        state.isLoading = true;
      },
    )
    .addCase(
      effects.getCompanyKeystat.fulfilled,
      (state: CompanyProfileState, action: PayloadAction<any>) => {
        const { error, data, symbol } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.keystat[symbol] = data;
        }

        state.isLoading = false;
      },
    )
    .addCase(
      effects.getFinancialReport.pending,
      (state: CompanyProfileState) => {
        state.isLoading = true;
      },
    )
    .addCase(
      effects.getFinancialReport.fulfilled,
      (state: CompanyProfileState, action: PayloadAction<FinancialPayload>) => {
        const { error, data, message, symbol } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.financial[symbol] = data;
        }

        state.message = message;
        state.isLoading = false;
      },
    )
    .addCase(effects.getAnalystRating.pending, (state: CompanyProfileState) => {
      state.isLoading = true;
    })
    .addCase(
      effects.getAnalystRating.fulfilled,
      (state: CompanyProfileState, action: PayloadAction<AnalystPayload>) => {
        const { error, data, message, symbol } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.analyst[symbol] = data;
        }

        state.message = message;
        state.isLoading = false;
      },
    )
    .addCase(
      effects.getAnalystConsensus.pending,
      (state: CompanyProfileState) => {
        state.isLoadingAnalystConsensus = true;
      },
    )
    .addCase(
      effects.getAnalystConsensus.fulfilled,
      (state: CompanyProfileState, action: PayloadAction<AnalystPayload>) => {
        const { error, data, message, symbol } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.analystConsensus[symbol] = data;
        }

        state.message = message;
        state.isLoadingAnalystConsensus = false;
      },
    )
    .addCase(effects.getPinnedPost.pending, (state: CompanyProfileState) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(
      effects.getPinnedPost.fulfilled,
      (
        state: CompanyProfileState,
        action: PayloadAction<PinnedPostPayload>,
      ) => {
        const { error, message, data, symbol } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.pinnedPost.data[symbol] = data;
          state.error = null;
        }

        state.message = message;
        state.isLoading = false;
      },
    )
    .addCase(
      effects.getPinnedPost.rejected,
      (
        state: CompanyProfileState,
        action: PayloadAction<PinnedPostPayload>,
      ) => {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    )
    .addCase(
      effects.getCompanySubsidiary.pending,
      (state: CompanyProfileState) => {
        state.isLoadingCompanySubsidiary = true;
      },
    )
    .addCase(
      effects.getCompanySubsidiary.fulfilled,
      (
        state: CompanyProfileState,
        action: PayloadAction<CompanySubsidiaryPayload>,
      ) => {
        const { error, data, message, symbol } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.companySubsidiary[symbol] = data;
        }

        state.message = message;
        state.isLoadingCompanySubsidiary = false;
      },
    );
};

const companyProfileSlice = createSlice({
  name: 'companyProfile',
  initialState,
  reducers,
  extraReducers,
});

export default companyProfileSlice;
