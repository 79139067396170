import requestConfigExodus from 'config/requestExodus';
import { API_EXODUS_URL } from 'constants/api';
import { APIResponse } from '../../../@types/api-response';

const requestExodus = requestConfigExodus();
const requestExodusNoAlert = requestConfigExodus(false);

const FUNDACHART_URL = `${API_EXODUS_URL}/fundachart`;

const getFundachartMetric = (): Promise<APIResponse> =>
  requestExodus.get(`${FUNDACHART_URL}/metrics?metric_name=fundachart`);

interface getFundachartParams {
  timeframe: string;
  companies: string;
  item: string;
}

interface IGetFundachartDefaultDataParams {
  token: string;
}

interface IGetFundachartTokenParams {
  symbol?: string;
  data_type?: string;
  time_frame?: string;
}

interface IGetFundachartKeystatDataParams extends IGetFundachartDefaultDataParams {
  symbol: string;
  params: string;
}

const getFundachart = (params: getFundachartParams): Promise<APIResponse> =>
  requestExodus.get(FUNDACHART_URL, { params });

interface saveTemplateParams {
  symbol: string;
  name: string;
  fitem_id: string;
  time_frame: string;
}

const getFundachartTemplate = (): Promise<APIResponse> =>
  requestExodus.get(`${FUNDACHART_URL}/templates`);

const saveFundachartTemplate = (
  data: saveTemplateParams,
): Promise<APIResponse> => requestExodus.post(FUNDACHART_URL, data);

const removeFundachartTemplate = (templateId: string): Promise<APIResponse> =>
  requestExodus.delete(`${FUNDACHART_URL}/${templateId}`);

const getFundachartDefaultData = (token: string): Promise<Response> =>
  fetch(`${FUNDACHART_URL}/data?token=${token}`, { method: 'GET' });

const getFundachartToken = (body?: IGetFundachartTokenParams): Promise<APIResponse> =>
  body ? requestExodusNoAlert.post(`${FUNDACHART_URL}/tokens`, body) : requestExodusNoAlert.post(`${FUNDACHART_URL}/tokens`);

const getFundachartKeystatData = ({ token, symbol, params }: IGetFundachartKeystatDataParams): Promise<any> =>
  fetch(`${FUNDACHART_URL}/${symbol}/ratios?${params}`, { method: 'GET', headers: { Authorization: token } });

export default {
  getFundachartMetric,
  getFundachart,
  getFundachartTemplate,
  saveFundachartTemplate,
  removeFundachartTemplate,
  getFundachartDefaultData,
  getFundachartToken,
  getFundachartKeystatData,
};
