import requestConfig from 'config/requestExodus';
import { API_EXODUS_URL } from 'constants/api';
import { APIResponse } from '../../../@types/api-response';

const request = requestConfig();

const COMPARISON_URL = `${API_EXODUS_URL}/comparison`;

const getComparisonMetric = (): Promise<APIResponse<any>> =>
  request.get(`${COMPARISON_URL}/metrics`);

interface getComparisonParams {
  symbol: string;
}

const getIndustrySectorRatio = (symbol: string): Promise<APIResponse<any>> =>
  request.get(`${COMPARISON_URL}/${symbol}/industries`);

const getComparison = ({
  symbol,
}: getComparisonParams): Promise<APIResponse<any>> =>
  request.get(`${COMPARISON_URL}/${symbol}/ratios`);

const getComparisonTemplate = (symbol: string): Promise<APIResponse> =>
  request.get(`${COMPARISON_URL}/${symbol}/templates`);

interface saveTemplateParams {
  symbol: string;
  content: string;
  layout_name: string;
}
const saveComparisonTemplate = (
  data: saveTemplateParams,
): Promise<APIResponse> => request.post(`${COMPARISON_URL}/templates`, data);

const deleteComparisonTemplate = (templateId: string): Promise<APIResponse> =>
  request.delete(`${COMPARISON_URL}/templates/${templateId}`);

const loadComparisonTemplate = (templateId: string): Promise<APIResponse> =>
  request.get(`${COMPARISON_URL}/templates/${templateId}`);

export default {
  getComparisonMetric,
  getComparison,
  getComparisonTemplate,
  saveComparisonTemplate,
  deleteComparisonTemplate,
  loadComparisonTemplate,
  getIndustrySectorRatio,
};
