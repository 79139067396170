import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import fundachartApi from 'lib/api/fundachart';
import { CommonPayload } from '../../../@types/action-payload';

// APIs

// Initial state
const initialState = {
  metric: [],
  template: {},
  chart: {},
  isLoading: false,
  error: null,
  message: '',
};

type FundachartState = typeof initialState;

const fundachartSelector = (state) => state.entities.fundachart;
export const selectors = createSelector(
  fundachartSelector,
  (fundachart: FundachartState) => ({
    ...fundachart,
  }),
);

// Action types
const CONTEXT = '@redux/fundachart';

const actionType = {
  GET_METRIC: `${CONTEXT}/GET_METRIC`,
  GET_FUNDACHART: `${CONTEXT}/GET_FUNDACHART`,
  GET_TEMPLATE: `${CONTEXT}/GET_TEMPLATE`,
  SAVE_TEMPLATE: `${CONTEXT}/SAVE_TEMPLATE`,
  REMOVE_TEMPLATE: `${CONTEXT}/REMOVE_TEMPLATE`,
};

interface BaseFundachartPayload extends CommonPayload {
  symbol?: string;
}

interface getFundachartParams {
  symbol: string;
  timeframe: string;
  companies: string;
  item: string;
}

interface saveTemplateParams {
  symbol: string;
  name: string;
  fitem_id: string;
  time_frame: string;
}

// Side effects
export const effects = {
  getFundachartMetric: createAsyncThunk<CommonPayload>(
    actionType.GET_METRIC,
    async () => {
      try {
        const response = await fundachartApi.getFundachartMetric();

        if (!response.data) {
          throw new Error('Attempt to get fundachart metric failed');
        }

        response.data.data = response.data.data.map((dt) => ({
          id: dt.fitem_id,
          name: dt.fitem_name,
          show_chart_icon: dt.show_chart_icon,
          childs: dt.child.map((dtc1) => ({
            id: dtc1.fitem_id,
            name: dtc1.fitem_name,
            show_chart_icon: dtc1.show_chart_icon,
            childs: dtc1.child.map((dtc2) => ({
              id: dtc2.fitem_id,
              name: dtc2.fitem_name,
              show_chart_icon: dtc2.show_chart_icon,
              childs: [],
            })),
          })),
        }));

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  getFundachart: createAsyncThunk<BaseFundachartPayload, getFundachartParams>(
    actionType.GET_FUNDACHART,
    async ({ symbol, ...params }) => {
      try {
        const response = await fundachartApi.getFundachart(params);
        let newResponse = null;

        if (!response.data) {
          throw new Error('Attempt to get fundachart failed');
        } else {
          newResponse = {
            data: [],
            message: response.data.message,
          };
          (response.data?.data || []).forEach((dt: any) => {
            dt.ratios.forEach((ratio: any) => {
              const chartData = ratio.chart_data.map((cd: any) => [
                cd.date * 1000,
                cd.value,
              ]);
              if (chartData.length) {
                const newData = {
                  data: chartData,
                  companyid: dt.company_id,
                  company: dt.company_name,
                  itemid: ratio.item_id,
                  name: `${dt.company_name} ${ratio.item_name}`,
                  yAxis: ratio.yaxis_id - 1,
                  suffix: ratio.suffix,
                  groupdata: ratio.group_data,
                  decimal: ratio.decimal_point,
                  show: true,
                  itemName: ratio.item_name,
                };
                newResponse.data.push(newData);
              }
            });
          });
        }

        const { error, message, data } = newResponse || response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, symbol };
      } catch (error) {
        return { error };
      }
    },
  ),
  getFundachartTemplate: createAsyncThunk<CommonPayload>(
    actionType.GET_TEMPLATE,
    async () => {
      try {
        const response = await fundachartApi.getFundachartTemplate();

        if (!response.data) {
          throw new Error('Attempt to get fundachart template failed!');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  saveFundachartTemplate: createAsyncThunk<CommonPayload, saveTemplateParams>(
    actionType.SAVE_TEMPLATE,
    async (params) => {
      try {
        const response = await fundachartApi.saveFundachartTemplate(params);
        if (!response.data) {
          throw new Error('Attempt to save template failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  removeFundachartTemplate: createAsyncThunk<CommonPayload, string>(
    actionType.REMOVE_TEMPLATE,
    async (templateId) => {
      try {
        const response = await fundachartApi.removeFundachartTemplate(
          templateId,
        );

        if (!response.data) {
          throw new Error('Attempt to remove template failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
};

// Reducers
const reducers = {};

const extraReducers = (builder) => {
  builder
    .addCase(
      effects.getFundachartMetric.fulfilled,
      (state: FundachartState, action: PayloadAction<CommonPayload>) => {
        const { error, data, message } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.metric = data;
          state.error = null;
        }

        state.isLoading = false;
        state.message = message;
      },
    )
    .addCase(effects.getFundachart.pending, (state: FundachartState) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(
      effects.getFundachart.fulfilled,
      (
        state: FundachartState,
        action: PayloadAction<BaseFundachartPayload>,
      ) => {
        const { error, message, data, symbol } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.chart[symbol] = data;
          state.error = null;
        }

        state.isLoading = false;
        state.message = message;
      },
    )
    .addCase(
      effects.getFundachartTemplate.fulfilled,
      (state: FundachartState, action: PayloadAction<CommonPayload>) => {
        const { error, message, data } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.template = data;
          state.error = null;
        }

        state.isLoading = false;
        state.message = message;
      },
    );
  // save and remove fundachart template doesn't affect reducer since everytime those two
  // executed, we will re-fetch the template list
};

const fundachart = createSlice({
  name: 'fundachart',
  initialState,
  reducers,
  extraReducers,
});

export default fundachart;
