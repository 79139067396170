/* eslint-disable no-return-assign */
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import comparisonApi from 'lib/api/comparison';
import { CommonPayload } from '../../../@types/action-payload';

// APIs

// Initial state

const initialState = {
  competitors: [],
  metric: [],
  template: {},
  comparison: {},
  isLoading: true,
  error: null,
  message: '',
  shouldLoadDetail: false,
};

type ComparisonState = typeof initialState;

// selectors
const comparisonSelector = (state) => state.entities.comparison;
export const selectors = createSelector(
  comparisonSelector,
  (comparison: ComparisonState) => ({
    ...comparison,
  }),
);

// Action types
const CONTEXT = '@redux/comparison';

const actionType = {
  GET_METRIC: `${CONTEXT}/GET_METRIC`,
  GET_COMPARISON: `${CONTEXT}/GET_COMPARISON`,
  ADD_COMPARISON: `${CONTEXT}/ADD_COMPARISON`,
  GET_TEMPLATE: `${CONTEXT}/GET_TEMPLATE`,
  SAVE_TEMPLATE: `${CONTEXT}/SAVE_TEMPLATE`,
  DELETE_TEMPLATE: `${CONTEXT}/DELETE_TEMPLATE`,
  LOAD_TEMPLATE: `${CONTEXT}/LOAD_TEMPLATE`,
};

interface BasePayload<T = any> extends CommonPayload<T> {
  symbol?: string;
}

interface addComparisonParams {
  sourceSymbol: string;
  symbols: string[];
}

interface addComparisonPayload extends BasePayload {
  sourceSymbol?: string;
}

interface addTemplateParams {
  symbol: string;
  content: string;
  layout_name: string;
}

interface loadTemplateParams {
  symbol: string;
  templateId: string;
}

// eslint-disable-next-line max-len
const formatComparisonData = (data) =>
  data.reduce((ac, a) => ({ ...ac, [a.fitem_id]: a.value }), {});

// Effects
export const effects = {
  getMetric: createAsyncThunk<CommonPayload>(
    actionType.GET_METRIC,
    async () => {
      try {
        const response = await comparisonApi.getComparisonMetric();

        if (!response.data) {
          throw new Error('Attempt to get comparison metric failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  // Get initial comparison data
  getComparisons: createAsyncThunk<BasePayload, string>(
    actionType.GET_COMPARISON,
    async (symbol) => {
      try {
        const response = await comparisonApi.getIndustrySectorRatio(symbol);

        if (!response.data) {
          throw new Error('Attempt to get comparisons failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, symbol };
      } catch (error) {
        return { error };
      }
    },
  ),
  addComparison: createAsyncThunk<addComparisonPayload, addComparisonParams>(
    actionType.ADD_COMPARISON,
    async ({ sourceSymbol, symbols }) => {
      try {
        const response = await Promise.all(
          symbols.map((symbol) => comparisonApi.getComparison({ symbol })),
        );

        const mappedRes = response.reduce((a, b) => {
          return [
            ...a,
            ...(b?.data?.data && !b.data?.error ? [b.data.data] : []),
          ];
        }, []);

        if (!mappedRes.length) {
          throw new Error('Attempt to add comparison failed');
        }

        return { data: mappedRes, sourceSymbol };
      } catch (error) {
        return { error };
      }
    },
  ),
  getTemplates: createAsyncThunk<BasePayload, string>(
    actionType.GET_TEMPLATE,
    async (symbol) => {
      try {
        const response = await comparisonApi.getComparisonTemplate(symbol);

        if (!response.data) {
          throw new Error('Attempt to get comparison template failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, symbol };
      } catch (error) {
        return { error };
      }
    },
  ),
  saveTemplate: createAsyncThunk<any, addTemplateParams>(
    actionType.SAVE_TEMPLATE,
    async (params) => {
      try {
        const response = await comparisonApi.saveComparisonTemplate(params);

        if (!response.data) {
          throw new Error('Attempt to save comparison template failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  removeTemplate: createAsyncThunk<any, string>(
    actionType.DELETE_TEMPLATE,
    async (templateId) => {
      try {
        const response = await comparisonApi.deleteComparisonTemplate(
          templateId,
        );

        if (!response.data) {
          throw new Error('Attempt to delete comparison template failed');
        }

        const { data, error, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  loadTemplate: createAsyncThunk<BasePayload, loadTemplateParams>(
    actionType.LOAD_TEMPLATE,
    async ({ symbol, templateId }) => {
      try {
        const response = await comparisonApi.loadComparisonTemplate(templateId);

        if (!response.data) {
          throw new Error('Attempt to load template failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, symbol };
      } catch (error) {
        return { error };
      }
    },
  ),
};

// Reducers
const reducers = {
  removeComparison: (state: ComparisonState, action: PayloadAction<any>) => {
    const { symbol, sourceSymbol } = action.payload;
    const comparison = { ...state.comparison[sourceSymbol] };
    delete comparison[symbol];

    state.comparison[sourceSymbol] = comparison;

    state.competitors = state.competitors.filter(
      (x) => x.toUpperCase() !== symbol.toUpperCase(),
    );
  },
  setShouldLoadDetail: (
    state: ComparisonState,
    action: PayloadAction<boolean>,
  ) => {
    state.shouldLoadDetail = action.payload;
  },
};

const extraReducers = (builder) => {
  builder
    .addCase(
      effects.getMetric.fulfilled,
      (state: ComparisonState, action: PayloadAction<any>) => {
        const { error, message, data } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.metric = data;
          state.error = null;
        }

        state.message = message;
      },
    )
    .addCase(effects.getComparisons.pending, (state: ComparisonState) => {
      state.error = null;
      state.isLoading = true;
    })
    .addCase(
      effects.getComparisons.fulfilled,
      (state: ComparisonState, action: PayloadAction<BasePayload>) => {
        const { error, message, data, symbol } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.competitors = data.competitor.map((c) =>
            c.symbol.toUpperCase(),
          );
          const comparisonData = {
            _industry: formatComparisonData(data.industry),
            _sector: formatComparisonData(data.sector),
          };

          state.comparison[symbol] = comparisonData;
          state.error = null;
          state.shouldLoadDetail = true;
        }

        state.message = message;
        state.isLoading = false;
      },
    )
    .addCase(
      effects.addComparison.fulfilled,
      (state: ComparisonState, action: PayloadAction<addComparisonPayload>) => {
        const { error, message, data, sourceSymbol } = action.payload;

        if (error) {
          state.error = error;
        } else {
          data.forEach((d) => {
            state.comparison[sourceSymbol][d.symbol] = formatComparisonData(
              d.data_value,
            );
            if (state.competitors.indexOf(d.symbol) === -1)
              state.competitors = [
                ...state.competitors,
                d.symbol.toUpperCase(),
              ];
          });
          state.error = null;
        }

        state.message = message;
        state.isLoading = false;
      },
    )
    .addCase(
      effects.getTemplates.fulfilled,
      (state: ComparisonState, action: PayloadAction<BasePayload>) => {
        const { error, message, data, symbol } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.template[symbol] = data.map((t) => ({
            id: t.save_id,
            name: t.save_name,
          }));
          state.error = null;
        }

        state.message = message;
        state.isLoading = false;
      },
    )
    // Add and remove template layout doesn't affect reducer since we will refetch template list
    // after success do both
    .addCase(effects.loadTemplate.pending, (state: ComparisonState) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(
      effects.loadTemplate.fulfilled,
      (state: ComparisonState, action: PayloadAction<BasePayload>) => {
        const { error, message, data, symbol } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.competitors = data.competitor.map((c) => c.symbol);
          const comparisonData = {
            _industry: formatComparisonData(data.industry),
            _sector: formatComparisonData(data.sector),
          };

          state.comparison[symbol] = comparisonData;
          state.error = null;
          state.shouldLoadDetail = true;
        }

        state.message = message;
        state.isLoading = false;
      },
    );
};

const comparison = createSlice({
  name: 'comparison',
  initialState,
  reducers,
  extraReducers,
});

export default comparison;
