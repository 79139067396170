import { Table } from "antd";
import styled, { css } from "styled-components";
import color from "../_style/color";

export default styled(Table)
  .withConfig({
    shouldForwardProp: (props) => !["borderless"].includes(props),
  })
  .attrs((props) => ({ borderless: props.borderless }))`
    .ant-table-cell {
      padding: 0 !important;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      border-bottom: 1px solid ${color.aliceBlue};
    }

    .ant-table-tbody > tr {
      position: relative;
    }
    .ant-table-tbody > tr:hover > td {
      background: ${color.aliceBlue};
    }
    .ant-pagination-item-active{
      border-color : ${color.primary};
    }
    .ant-pagination-item-active a,
    .ant-pagination-item:focus a, 
    .ant-pagination-item:hover a{
      color : ${color.primary}
    }

    ${({ borderless }) =>
      borderless &&
      css`
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
          border: none;
        }
      `}
`;
